@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");

@font-face {
  font-family: 'Millik';
  src: local('Millik'), url(../public/assets/Millik.ttf) format('tff');
}

:root {
  --mainColor: #1890ff;
  --whiteColor: #fafafa;
  --blackColor: #000;
  --greyColor1: rgb(237, 234, 243);
}

html {
  scroll-behavior: smooth;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Quicksand", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
::-webkit-scrollbar-thumb {
  background-color: var(--greyColor1) !important;
  opacity: 0.2 !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: inherit !important;
}

a {
  text-decoration: none !important;
  color: inherit;
  color: var(--mainColor);
}

a:hover {
  text-decoration: none;
}



.my__card {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 3%), 0 2px 10px 0 rgb(0 0 0 / 3%);
  background: white;
}
.my__card__shadow {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

tbody {
  cursor: pointer;
}

.ant-popover-content {
  max-width: 250px;
}

.register__layout__wrapper {
  min-height: 100vh;
  width: 100vw;
}
.register__left__side {
  min-height: 100vh;
  padding-bottom: 0.5rem;
  background: var(--mainColor);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
.register__right__side {
  min-height: 100vh;
  background: var(--greyColor1);
  display: grid;
  place-items: center;
}
.upper_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--whiteColor);
}
.upper_content p {
  text-align: center;
  line-height: 1.1;
}
.upper_content .heading {
  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
}

.ant-form-item {
  margin-bottom: 9px !important;
}

.ant-input-group {
  width: auto !important;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .register__left__side {
    min-height: 50vh;
  }
  .register__right__side {
    padding: 2rem 1rem;
    min-height: 50vh;
  }
}
